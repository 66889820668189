var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-end mb-2"},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.checkPerUser},on:{"click":_vm.openCreate}},[_vm._v(" 新規ポイント処理登録 ")])],1),_c('Table',{ref:"table",attrs:{"total":_vm.total,"attr":{
        dense: true,
        'item-key': 'id',
      },"funReset":_vm.getData,"itemsPerPage":10,"itemsPerPageOptions":[5, 10, 30, 40, 50, 100],"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span-hover-pop',{attrs:{"value":item.name}})]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("tvpBulkProcessType")(item.type))+" ")]}},{key:"item.point",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toThousands")(item.point))+" ")]}},{key:"item.createdBy",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdStaff && item.createdStaff.name)+" ")]}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeISONoSecond")(item.createdAt))+" ")]}},{key:"item.executedAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeISONoSecond")(item.executedAt))+" ")]}},{key:"item.memberCount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toThousands")(item.memberCount))+" ")]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"t-btn--prm",attrs:{"rounded":"","icon":""},on:{"click":function($event){return _vm.openUpload(item.id)}}},[_c('v-icon',[_vm._v(" mdi-upload")])],1),_c('v-btn',{staticClass:"t-btn--gray",attrs:{"rounded":"","icon":""},on:{"click":function($event){return _vm.openEdit(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ml-1-icon",attrs:{"color":"primary","disabled":_vm.executeDisabled(item) || !_vm.checkPerUser},on:{"click":function($event){return _vm.openExecute(item)}}},[_vm._v(" 処理実行 ")])],1)]}}])}),_c('TVPBatchCreateUpdateDialog',{attrs:{"id":_vm.updateId,"checkPerUser":_vm.checkPerUser},on:{"reload":_vm.reload},model:{value:(_vm.createUpdateDialog),callback:function ($$v) {_vm.createUpdateDialog=$$v},expression:"createUpdateDialog"}}),_c('TVPBatchAddClientsDialog',{attrs:{"id":_vm.addClientId,"checkPerUser":_vm.checkPerUser},on:{"reload":_vm.reload},model:{value:(_vm.addClientDialog),callback:function ($$v) {_vm.addClientDialog=$$v},expression:"addClientDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }