<template>
  <div>
    <div class="d-flex justify-end mb-2">
      <v-btn @click="openCreate" color="primary" :disabled="!checkPerUser">
        新規ポイント処理登録
      </v-btn>
    </div>
    <Table
        :total="total"
        :attr="{
          dense: true,
          'item-key': 'id',
        }"
        ref="table"
        :funReset="getData"
        :itemsPerPage="10"
        :itemsPerPageOptions="[5, 10, 30, 40, 50, 100]"
        :headers="headers"
        :items="items"
      >
        <template v-slot:item.name="{item}">
          <span-hover-pop :value="item.name" />
        </template>
        <template v-slot:item.type="{item}">
          {{ item.type | tvpBulkProcessType }}
        </template>
        <template v-slot:item.point="{item}">
          {{ item.point | toThousands }}
        </template>
        <template v-slot:item.createdBy="{item}">
          {{ item.createdStaff && item.createdStaff.name }}
        </template>
        <template v-slot:item.createdAt="{item}">
          {{ item.createdAt | formatDateTimeISONoSecond }}
        </template>
        <template v-slot:item.executedAt="{item}">
          {{ item.executedAt | formatDateTimeISONoSecond }}
        </template>
        <template v-slot:item.memberCount="{item}">
          {{ item.memberCount | toThousands }}
        </template>
        <template v-slot:item.action="{item}">
          <div class="d-flex">
            <v-btn @click="openUpload(item.id)" rounded icon class="t-btn--prm" >
              <v-icon>  mdi-upload</v-icon>
            </v-btn>
            <v-btn @click="openEdit(item.id)" rounded icon class="t-btn--gray">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="openExecute(item)" color="primary" :disabled="executeDisabled(item) || !checkPerUser"
              class="ml-1-icon"><!-- 1button width difference -->
              処理実行
            </v-btn>
          </div>
        </template>
    </Table>
    <TVPBatchCreateUpdateDialog v-model="createUpdateDialog" :id="updateId" :checkPerUser="checkPerUser" @reload="reload"  />
    <TVPBatchAddClientsDialog v-model="addClientDialog" :id="addClientId" :checkPerUser="checkPerUser" @reload="reload" />
  </div>
</template>

<script>
import Table from '@/components/Table/index.vue';
import SpanHoverPop from '../../components/shared/SpanHoverPop.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions'
import TVPBatchCreateUpdateDialog from './TVPBatchCreateUpdateDialog.vue';
import TVPBatchAddClientsDialog from './TVPBatchAddClientsDialog .vue';

export default {
  components: {Table, SpanHoverPop, TVPBatchCreateUpdateDialog, TVPBatchAddClientsDialog},
  data( ) {
    return {
      total: 0,
      items: [],
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      createUpdateDialog: false,
      updateId: null,
      addClientDialog: false,
      addClientId: null,
      page: null,
    }
  },
  computed: {
    headers() {
      return [
        { text: '', value: 'action', align:'center', sortable: false},
        { text: 'ID', value: 'id', align:'center', sortable: false},
        { text: '処理内容', value: 'name', align:'center', sortable: false},
        { text: '処理種別', value: 'type', align:'center', sortable: false},
        { text: 'ポイント数', value: 'point', align:'center', sortable: false},
        { text: 'ステータス', value: 'status', align:'center', sortable: false},
        { text: '登録者', value: 'createdBy', align:'center', sortable: false},
        { text: '登録日時', value: 'createdAt', align:'center', sortable: false},
        { text: '処理日時', value: 'executedAt', align:'center', sortable: false},
        { text: '会員数', value: 'memberCount', align:'center', sortable: false},
      ]
    }
  },
  mounted() {
    this.$refs.table.reset();
  },
  methods: {
    executeDisabled(item) {
      return !!item.executedAt
    },
    openUpload(id) {
      this.addClientId = id;
      this.addClientDialog = true
    },
    openEdit(id) {
      this.updateId = id;
      this.createUpdateDialog = true;
    },
    openExecute(row) {
      this.$confirm({
        title: '処理実行',
        message: `${row.name}のTVP一括処理を実行します。取消はできません。よろしいですか？`,
        ok: async () => {
          await this.$store.dispatch('executeTVPBulkProcess', { id: row.id });
          await this.reload();
        }
      })
    },
    openCreate() {
      this.updateId = null;
      this.createUpdateDialog = true;
    },
    async getData(variables) {
      this.page = variables.pagination;
      await this.reload();
    },
    async reload() {
      const result = await this.$store.dispatch('tvpBulkProcessList', {
        skip: this.page.skip,
        take: this.page.take,
      })
      this.total = result.total;
      this.items = result.items;
    }
  }
}
</script>

<style>

</style>