<template>
  <v-dialog :value="value" @input="emitInput" persistent max-width="1200">
    <v-card>
      <v-form v-model="valid">
        <v-card-title>
          <span class="dialog-headline">
            TVP一括処理内容{{ isNew ? '登録' : '更新' }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-container>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> ID </v-col>
                    <v-col cols="8">
                      {{ id }}
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> 処理内容 </v-col>
                    <v-col cols="8">
                      <v-text-field v-model="form.name" :rules="[$rules.required]" />
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> 処理種別 </v-col>
                    <v-col cols="8">
                      <v-select :items="typeItems" v-model="form.type" :rules="[$rules.required]" :disabled="!isNew" />
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> ポイント数 </v-col>
                    <v-col cols="8">
                      <tvos-int-input required v-model="form.point" :rules="[$rules.required]" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="6" v-if="!isNew">
                <v-container>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> 登録者 </v-col>
                    <v-col cols="8">
                      {{ data.createdStaff && data.createdStaff.name }}
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> 登録日時 </v-col>
                    <v-col cols="8">
                      {{ data.createdAt | formatDateTimeISONoSecond }}
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> 処理日時 </v-col>
                    <v-col cols="8">
                      {{ data.executedAt | formatDateTimeISONoSecond }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4"> 備考 </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="form.remarks" outlined />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="error" @click="close">
            キャンセル
          </v-btn>
          <v-btn color="primary" @click="saveClick" :disabled="!checkPerUser || !canSave">
            {{ isNew ? '作成' : '更新'}}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
const initForm = () => ({
  name: '',
  type: null,
  point: null,
  remarks: null
})

export default {
  props: {
    value: Boolean,
    id: Number,
    checkPerUser: Boolean
  },
  data () {
    return {
      form: initForm(),
      data: {},
      valid: false
    }
  },
  computed: {
    typeItems() {
      return [
        { text: '付与', value: 'Grant' },
        { text: '取消', value: 'Revoke' },
      ];
    },
    isNew() {
      return !this.id
    },
    canSave() {
      return this.isNew || !this.data.executedAt
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler(v) {
        if (v) {
          this.form = initForm();
          this.data = await this.$store.dispatch('getTVPBulkProcess', this.id);
          this.form = {
            ...initForm(),
            name: this.data.name,
            type: this.data.type,
            point: this.data.point,
            remarks: this.data.remarks,
          }
        }
      }
    }
  },
  methods: {
    emitInput(v) {
      this.$emit('input', v)
    },
    close () {
      this.$emit('input', false)
    },
    reloadAndClose() {
      this.$emit('reload', true);
      this.close()
    },
    async saveClick() {
      if (this.isNew) {
        // call create api
        await this.$store.dispatch('createTvpBulkProcess', this.form);
        this.reloadAndClose()
      } else {
        // call update api
        await this.$store.dispatch('updateTvpBulkProcess', {
          id: this.id,
          ...this.form
        });
        this.reloadAndClose()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.input-row {
  align-items: baseline;
}
.lable {
  text-align: right;
}
</style>