<template>
  <span :title="value">
    {{ value }}
  </span>
</template>

<script>
export default {
  props: {
    value: String
  }
}
</script>
