<template>
  <v-dialog :value="value" @input="emitInput" persistent max-width="1200">
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-card-title>
          <span class="dialog-headline">
            会員アップロード</span
          >
        </v-card-title>
        <v-card-text>
          <h5>対象会員（会員番号／改行区切り）</h5>
          <v-textarea v-model="memberIdsText" :rules="[$rules.required, noDuplicate]" outlined />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="error" @click="close">
            キャンセル
          </v-btn>
          <v-btn color="primary" @click="saveClick" :disabled="!checkPerUser || !canSave">
            登録
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <h5>登録結果</h5>
          <div>
            <div>成功: {{successClients.length | toThousands}}件</div>
            <div>エラー: {{errorClients.length | toThousands}}件</div>           
            <div v-for="client in errorClients" :key="client.id">
              {{ client.memberId }} {{ client.errorReason | errorReason }}
            </div>
          </div>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    value: Boolean,
    id: Number,
    checkPerUser: Boolean
  },
  filters: {
    errorReason(v) {
      switch (v) {
        case 'ClientNotFound': return '会員番号が存在しません'
        case 'MembershipCancelled': return '退会済みの会員です'
        case 'NoVsOrVm': return '有効な権利がありません'
        case 'InsufficientPoint': return '所有TVPが不足しています'
        default: return v;
      }
    }
  },
  data () {
    return {
      data: null,
      memberIdsText: null,
      valid: false
    }
  },
  computed: {
    typeItems() {
      return [
        { text: '付与', value: 'Grant' },
        { text: '取消', value: 'Revoke' },
      ];
    },
    canSave() {
      return this.data && !this.data.executedAt && this.memberIdsText
    },
    memberIds () {
      if (!this.memberIdsText) return [];

      return this.memberIdsText.split('\n').map(line => line.trim()).filter(line => !!line)
    },
    successClients () {
      return this.data?.clients.filter(c => c.isValid) ?? []
    },
    errorClients() {
      return this.data?.clients.filter(c => !c.isValid) ?? []
    },
    noDuplicate () {
      const unique = [...new Set(this.memberIds)];
      return this.memberIds.length === unique.length || '会員番号に重複があります'
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler(v) {
        if (v)
          await this.reload();
      }
    },
    data: {
      immediate: true,
      handler(data) {
        if (data) {
          this.memberIdsText = data.clients.map(c => c.memberId).join('\n');
        }
      }
    }
  },
  methods: {
    async reload () {
      if (this.id)
        this.data = await this.$store.dispatch('getTVPBulkProcess', this.id);
    },
    emitInput(v) {
      this.$emit('input', v)
    },
    close () {
      this.$emit('input', false)
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('addClientsTVPBulkProcess', {
          id: this.id,
          memberIds: this.memberIds,
        });
        await this.reload();
      this.$emit('reload', true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.input-row {
  align-items: baseline;
}
.lable {
  text-align: right;
}
</style>